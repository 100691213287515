<template>
    <div>
      <!-- Container for the API response HTML content -->
      <div v-html="htmlContent" class="api-response-content"></div>
    </div>
</template>
  
<script>
  export default {
    props: {
      // Define a prop to accept the HTML content
      htmlContent: {
        type: String,
        required: true,
      },
    },
  };
</script>
  
<style>
  /* Add some styles if needed */
  .api-response-content {
    padding: 1rem;
    border-radius: 8px;
    border: 1px solid #ccc;
    margin-top: 1rem;
  }
</style>
  