<template>
  <div class="min-h-screen flex flex-col items-center">
    <div class="w-full max-w-2xl mx-auto p-4">
      <HeaderComponent />
      
      <!-- Dynamically switch between ImageUpload and ResponseComponent -->
      <ImageUpload v-if="currentComponent === 'ImageUpload'" @api-response-received="handleApiResponse" />
      <ResponseComponent v-if="currentComponent === 'ResponseComponent'" :html-content="apiResponseHtml" />
      
      <FooterComponent />
    </div>
  </div>
</template>

<script>
import HeaderComponent from './components/HeaderComp.vue';
import FooterComponent from './components/FooterComp.vue';
import ImageUpload from './components/ImageUpload.vue';
import ResponseComponent from './components/ResponseComp.vue';

export default {
  data() {
    return {
      currentComponent: 'ImageUpload',
      apiResponseHtml: null,
    };
  },
  components: {
    HeaderComponent,
    FooterComponent,
    ImageUpload,
    ResponseComponent
  },
  methods: {
    handleApiResponse(htmlContent) {
      this.apiResponseHtml = htmlContent;
      this.currentComponent = 'ResponseComponent';
    }
  }
};
</script>

