
<template>
  <div class="flex flex-col items-center justify-center w-full bg-gray-100">
    <div class="p-6 max-w-screen-sm mx-auto w-full bg-white rounded-xl shadow-md flex flex-col items-center space-y-4">

      <div class="camera-container border-8 rounded-lg" v-show="isCameraActive">
        <video ref="cameraStream" id="camera-stream" class="camera-stream"></video>
      </div>

      <div class="images-container p-2 border-2 border-slate-300 rounded-lg overflow-auto">
        <div v-for="(imageSrc, index) in imageData" :key="index" class="mt-4">
          <img :src="imageSrc" class="max-w-sm rounded-lg shadow-md" />
        </div>
      </div>      

      <!-- Flex container for buttons -->
      <input type="file" ref="fileInput" @change="onFileChange" accept="image/*" multiple class="hidden">
      <div class="flex space-x-8 justify-center">
        <button @click="triggerFileInput" class="upload-button px-4 py-2 bg-violet-50 text-violet-700 font-bold rounded-lg hover:bg-violet-100 transition duration-300">Upload Image</button>
        <!-- <button @click="handleButtonClick" class="photo-button px-4 py-2 bg-violet-50 text-violet-700 font-bold rounded-lg hover:bg-violet-100 transition duration-300">
          {{ isCameraActive ? 'Capture' : 'Take Photo' }}
        </button> -->
        <button
          :disabled="imageData.length === 0"
          @click="callAI"
          class="px-4 py-2 font-bold rounded-lg cursor-not-allowed transition duration-300"
          :class="{
            'bg-violet-50 hover:bg-violet-100 cursor-pointer text-violet-700': imageData.length > 0,
            'bg-gray-100 text-gray-400': imageData.length === 0
          }">
          Generate
        </button>
      </div>

      <!-- Overlay and Spinner -->
      <div v-if="isLoading" class="overlay">
        <div class="spinner"></div>
      </div>

      <!-- Display API Response -->
      <div v-if="apiResponse" class="response p-2 border-2 border-slate-300 rounded-lg overflow-auto" v-html="apiResponse"></div>

    </div> 
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageData: [],
      cameraStream: null,
      isCameraActive: false, 
      isLoading: false,
      apiResponse: null,         
    };
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    onFileChange(e) {
      const files = e.target.files;
      if (files.length > 0) {
        Array.from(files).forEach(file => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = (e) => {
            // Push the new image data URL to the existing imageData array
            this.imageData.push(e.target.result);
          };
        });
      }
    },    
    handleButtonClick() {
      if (this.isCameraActive) {
        this.capturePhoto();
        this.isCameraActive = false;
      } else {
        this.triggerCamera();
        this.isCameraActive = true;
      }
    },    
    triggerCamera() {
      navigator.mediaDevices.getUserMedia({ video: true, audio: false })
        .then(stream => {
          this.cameraStream = stream;
          this.$refs.cameraStream.srcObject = stream;
          this.$refs.cameraStream.play();
        })
        .catch(err => {
          console.error("Error accessing camera:", err);
        });
    },
    capturePhoto() {
      let canvas = document.createElement('canvas');
      let context = canvas.getContext('2d');
      let video = this.$refs.cameraStream;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      this.imageData.push(canvas.toDataURL('image/png'));
      this.cameraStream.getTracks().forEach(track => track.stop());
    },
    async callAI() {
      if (this.imageData.length > 0) {
        this.isLoading = true;
        this.apiResponse = null; // Reset/clear previous response        
        const firstImage = this.imageData[0];
        if (firstImage.startsWith('data:image/')) {
          try {
            
            //const response = await fetch('http://localhost:3001/generate-description', {
            const response = await fetch('generate-description', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ imageBase64: this.imageData[0] }), // Sending the first image as an example
            });
            if (response.ok) {
              const data = await response.json();
              console.log('AI Description:', data.description);

              const fullResponse = data.description;
              const start = fullResponse.indexOf('```html') + '```html'.length;
              const end = fullResponse.indexOf('```', start);
              const htmlContent = fullResponse.substring(start, end).trim();

              //this.apiResponse = htmlContent;
              this.$emit('api-response-received', htmlContent);

              // Handle the response data here (e.g., display the description)
            } else {
              console.error('Failed to generate description');
            }
          } catch (error) {
            console.error('Error calling the API:', error);
          }
        }
        else
        {
          console.error('Not base 64');
        }
        this.isLoading = false;
      }
    },
    async callAPI() {
      this.$emit('api-response-received', "Hello world");
    }    

  },
  beforeUnmount() {
    if (this.cameraStream) {
      this.cameraStream.getTracks().forEach(track => track.stop());
    }
  }  
};
</script>

<style>
.camera-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  box-shadow: 0 4px 8px rgba(0,0,0,0.5);
  /* Removed display: none; */
}
.camera-stream {
  width: 100%; /* Adjust as needed */
  height: auto; /* Adjust as needed */
}
.captured-image {
  width: 50%; /* Adjust as needed */
  height: auto; /* Adjust as needed */
}
.images-container {
  min-height: 600px; /* Adjust based on your needs */
  max-height: 600px; /* Adjust based on your needs */
  width: 100%; /* Take the full width of its parent */
  overflow-y: auto; /* Enable vertical scrolling */
}
.hidden {
  display: none;
}

/* Overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5); /* Light white overlay */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  z-index: 50; /* Ensure it's above other content */
}

/* Spinner */
.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 8px solid blue; /* Use your primary color */
  width: 64px; /* Larger spinner */
  height: 64px; /* Larger spinner */
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.response {
  margin-top: 20px;
  width: 100%; /* Take the full width of its parent */
  overflow-y: auto; /* Enable vertical scrolling */
}

table {
  @apply divide-y divide-gray-200 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg;
}

th {
  @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
}

td {
  @apply px-6 py-4 whitespace-nowrap text-sm text-gray-500;
}

ul {
  @apply list-disc pl-5 space-y-1;
}

li {
  @apply mb-1;
}
</style>

