<template>
    <header>
      <!-- Header content here -->
        <h1 class="text-4xl font-bold text-center text-violet-700 mb-2">AI Risk Assessment</h1>
        <hr class="border-b-2 border-violet-300"/>  
    </header>
</template>



